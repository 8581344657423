// Navigation Blocks
.nav-block-container {
  position: relative;
  width: 100%;

  h3 {
    color: $white;
    margin: 0;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px black;
    font-size: 8vw;

    @media ($phablet) {
      font-size: 5vw;
    }

    @media ($tablet) {
      font-size: 2vw;
    }

    @media ($page-container-max) {
      @include fontrem(4.4);
    }

  } // h3

  h3 {
    font-size: 8vw;

    @media ($phablet) {
      font-size: 5vw;
    }

    @media ($tablet) {
      font-size: 2.5vw;
    }

    @media ($page-container-max) {
      @include fontrem(4.4);
    }
  }

  p {
    font-family: $subHeaderFont;
    margin: 0;
    text-shadow: 1px 1px 1px black;
    font-size: 4vw;

    @media ($phablet) {
      font-size: 2.5vw;
    }

    @media ($tablet) {
      font-size: 1.6vw;
    }

    @media ($tablet-wide) {
      font-size: 1.3vw;
    }

    @media ($page-container-max) {
      @include fontrem(2.6);
    }
  } // p

  .nav-block {
    width: 100%;
    height: 49.2vw;
    max-height: 480px; // Since .page-container max-width is 1920
    color: $white;
    position: relative;
    border-bottom: none;

    &:last-of-type {
      border-bottom: none;
    }

    @media ($phablet) {
      float: left;
      width: 50%;
      border-bottom: none;

      &:nth-child(-n+2) {
        border-bottom: 1px solid $red;
      }

      &:nth-child(odd) {
        border-right: 1px solid $red;
      }
    }

    @media ($tablet) {
      width: 25%;
      height: 24.2vw;
      border-right: 1px solid $red !important; // to override nth-child rules

      &:nth-child(-n+2) {
        border-bottom: none;
      }

      &:nth-child(odd) {
        border-right: none;
      }

      &:last-of-type {
        border-right: none !important; // to override nth-child rules
      }
    }

    &.active .nav-block-hover {
      opacity: 1; // show darker on current page
    }

    .nav-block-hover {
      opacity: 0;
      width: 100%;
      height: 100%;
      background: rgba(darken($blue, 5%), 0.6);
      transition: opacity 0.2s ease-in-out, background 0.2s ease-in-out;

      &.hover-red {
        background: rgba(darken($red, 5%), 0.6);
      }

      &.hover-gray {
        background: rgba(darken($red, 5%), 0.6);
      }

      &.add-text-overlay {
        background: rgba($black, 0.5);
        opacity: 1.0;

        &:hover {
          background: rgba(darken($blue, 5%), 0.6);
        }

        &.hover-red:hover {
          background: rgba(darken($red, 5%), 0.6);
        }

        &.hover-gray:hover {
          background: rgba(darken($red, 5%), 0.6);
        }
      }
    }

    a {
      color: $white;
      text-decoration: none;

      &:hover .nav-block-hover {
        opacity: 1.0;
      }
    }

    &-bg {
      width: 100%;
      height: 100%;
      background-color: $blue;
    }

    .nav-block-footer {
      position: absolute;
      width: 100%;
      top: 50%;
      margin: 0 auto;
      padding: 0 20px 0;
      text-align: center;
      transform: translateY(-50%);

      @media ($tablet) {
        width: auto;
        top: auto;
        bottom: 0;
        transform: none;
        padding: 0 20px 10px;
        text-align: left;
      }

      &.top {
        // Move text to top on non-homepage nav blocks
        @media ($tablet) {
          top: 10%;
          bottom: auto;
        }
      }

    } // .nav-block-footer


    &.no-link {

      @media ($under-phablet) {
        display: none;
      }

      h3 {
        font-size: 8vw;

        @media ($phablet) {
          font-size: 5vw;
        }

        @media ($tablet) {
          font-size: 2.5vw;
        }

        @media ($page-container-max) {
          @include fontrem(4.4);
        }
      }

      .add-text-overlay:hover {
        background: rgba($black, 0.5) !important; // to override hover styles since there is no link
      }
    } // .nav-block.no-link

    &.solid-blue,
    &.solid-light-blue,
    &.solid-red,
    &.solid-gray {
      height: 36vw;

      @media ($tablet) {
        height: 8vw;
        max-height: 152px;
      }

      h3 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        padding-left: 20px;

        br {
          display: none;

          @media ($phablet) {
            display: block;
          }
        }
      }

      .icon-block img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        margin: 0 auto;
        max-width: 50%;
        max-height: 80%;
      }

      .section-col-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .col-50 {
          position: relative;
          width: 50%;
          height: 100%;
          float: left;
        }
      }

    } // .solid-[COLOR]

    &.solid-blue .nav-block-bg {
      background: $blue;
    }

    &.solid-light-blue .nav-block-bg {
      background: lighten($blue, 10%);
    }

    &.solid-red .nav-block-bg {
      background: $red;
    }

    &.solid-gray .nav-block-bg {
      background: $darkGray;
    }

  } // .nav-block

  .multiple-block-container {

    @media ($phablet) {
      float: left;
      width: 50%;
      border-right: 1px solid $red;

      &:last-child {
        border-right: none;
      }
    }

    @media ($tablet-wide) {
      width: 25%;
    }

    .nav-block {
      float: none;
      width: 100%;
      border-right: none !important;

      .nav-block-bg h3 {

        @media ($tablet) and ($under-tablet-wide) {
          font-size: 2.6vw;
        }

      }
    }

  } // .multiple-block-container

} // .nav-block-container

.mini-nav-blocks {

  @media ($desktop) {
    width: 50%;
    margin: 0 auto 0 25%;
  }

  ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
    float: left;

    @media ($phablet) and (max-width: 799px) {
      width: 50%;

      &:nth-of-type(1) {
        border-bottom: 1px solid $red;
      }

      &:nth-of-type(2) {
        border-bottom: 1px solid $red;
      }

      &:nth-of-type(2n+1) {
        border-right: 1px solid $red;
      }
    } // @media

    @media (min-width: 800px) {
      width: 25%;
      border-right: 1px solid $red;
    } // @media

    @media (min-width: 800px) and ($under-desktop) {
      &:nth-last-of-type(1) {
        border-right: 0;
      }
    } // @media

    @media ($desktop) {
      &:nth-last-of-type(1) {
        border-right: 0;
      }
    } // @media

    a {
      display: block;
      text-align: center;
      color: $blue;
      font-family: $headerFont;
      line-height: 1.1;
      padding: 1.5rem 0;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      @include fontrem(2);

      &:hover,
      &:focus {
        background: $blue;
        color: $white;
      }

      span {
        display: block;
        font-family: $bodyFont;
        @include fontrem(1.4);
      }
    }
  } // li
} // mini-nav-blocks
