// Add styles for printers.

@media print {

  * {
    color: black !important;
    max-width: 100%;
  }

} // end print media query
