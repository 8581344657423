//
// 404 Styles
// ------------------------------
.section-404 {
  width: 100%;
  margin: 0 auto;
  padding: 3rem 0 25rem;

  @media ($tablet) {
    padding-top: 8rem;
  }

  .page-title {
    text-align: center;
    margin-bottom: 1.5rem;

    @media ($tablet) {
      margin-bottom: inherit;
    }
  }

  p {
    text-align: center;
    margin: 1rem auto 3rem;
  }

} // .section-404

.search-container-404 {
  width: 100%;
  text-align: center;

  .search-field {
    border: 1px solid $blue;
  }

} // .search-container
