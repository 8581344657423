//
// MEDIA POSTS
// ------------------------------
.page-template-page-media-center {

  .media-col {
    padding-top: 3em;
  }

} // page-template-page-media-center

.media-post {
  margin-bottom: 3em;
  padding-left: 1em;
  padding-right: 1.5em;
  @include fontrem(1.6);

  @media ($tablet) {
    padding-left: 2em;
  }

  &-title {
    font-family: $headerFont;
    margin: 0;
    padding: 0;
    @include fontrem(2.8);

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-date {
    font-family: $headerFont;
    color: $darkGray;
    @include fontrem(1.8);
  }

  &-author {
    color: $blue;
    @include fontrem(1.2);
  }

  &-excerpt {
    @include fontrem(1.6);

    @media ($tablet) {
      @include fontrem(1.4);
    }

    p {
      margin-top: 0;
    }
  } // media-post-excerpt

  // featured post
  &.featured {
    background: rgba($blue, 0.25);
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-right: 1.5em;
  }

} //.media-post

.media-section {
  padding-top: 2em;
}

// Sidebar Content:

.media-sidebar-header {
  margin: 1em auto 0.5em !important;
  font-size: 24px !important;
}

.media-sidebar-list {
  list-style: none;
  padding: 0;
  margin: 0 0 40px;
  @include fontrem(1.4);

  &.media-post-list li {
    margin-bottom: 1em;
  }

  a {
    color: $black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.category-post-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.category-post-list,
.latest-post-list,
.archive-post-list {
  display: block;
  padding-right: 1.5rem;

  @media ($tablet) {
    padding-right: 3rem;
  }

  h3 {
    margin: 1em auto 0.5em;
    @include fontrem(2.4);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
    margin: 0 auto 0.5em;
    border-bottom: 1px solid $lightGray;
    @include fontrem(1.4);

    a {
      display: inline-block;
      text-decoration: none;
      color: inherit;
      padding: 0.2em 0 0.5em;

      &:hover,
      &:focus {
        color: $red;
      }
    } // a
  } // li
} // category-/latest-/archive-post-list

.archive-post-list {
  margin-bottom: 4rem;
}

// Pagination:

.blog-pagination {
  padding: 0 0 0 15px;
  margin: 0 auto 4rem;

  @media (min-width: 768px) {
    padding: 0 30px;
  }
}

.page-numbers {
  display: inline-block;
  padding: 7px 6px;
  margin: 0;
  font-family: $headerFont;
  line-height: 1;
  text-decoration: none;
  color: $blue;
  transition: background 0.2s;
  @include fontrem(2);

  @media ($tablet) {
    padding: 7px 10px;
  }

  &.current {
    background: $red;
    color: $white;
  }

  &.dots {
    padding: 7px 0;
  }
} // page-numbers

a.page-numbers:hover,
a.page-numbers:focus {
  background: $lightGray;
}

.blog-links {
  margin: 2em auto;

  &.single-links {
    margin: 2em auto -2em; // reverse 4em top-margin of ads
    text-align: center;
  }

  a {
    display: inline-block;
    margin: 0 auto;
    color: $blue;
    font-family: $headerFont;
    text-decoration: none;
    @include fontrem(1.8);
  }
} // blog-links

.blog-back-link {
  display: none;

  @media ($tablet) {
    display: block;
  }
}
