//
// GLOBAL STYLES
// ------------------------------
*,
*:before,
*:after {
  box-sizing: border-box;
}

::-moz-selection {
  background: #b3d4fc;

  // change as desired
  text-shadow: none;
}

::selection {
  background: #b3d4fc;

  // change as desired
  text-shadow: none;
}

// GLOBAL ELEMENTS

html {
  font-size: 10px; // set easy multiplier for rem units
  width: 100%;
}

body {
  background: $blue;
  font-family: $bodyFont;
  position: relative;
  color: $black;
  line-height: 1.5;
  width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include fontrem(1.6); // set default font-size back to 16px
}

a {
  color: $blue;
  text-decoration: underline;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $darkBlue;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $blue;
  font-family: $headerFont;
  font-weight: normal; // since headings likely use a webfont with a specific weight
  text-transform: uppercase;
  line-height: 1.2;
}

ul, ol {
  padding: 0 0 0 1.5em;
}

nav ul {
  list-style: none;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em auto;
  padding: 0;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

textarea {
  resize: vertical;
}

address {
  font-style: normal;
}

// HELPER CLASSES

.hidden {
  display: none !important;
}

.no-scroll {
  overflow: hidden;
}

.no-break {
  white-space: nowrap;
}

.cf,
.clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
} // cf, clearfix

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px,0px 0px, 0px 0px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.top-margin {
  margin-top: 20px;
}

.text-center {
  text-align: center !important;
}

.text-red {
  color: $red !important;
}

.text-blue {
  color: $blue !important;
}

.left-border {
  border-left-width: 1px !important;
  border-left-style: solid;
  border-color: $blue;
}

.border-red {
  border-color: $red !important;
}

.border-blue {
  border-color: $blue !important;
}

.text-centered {
  text-align: center !important;
}

// Buttons
.btn {
  display: inline-block;
  width: 100%;
  padding: 10px;
  border: 2px solid $red;
  color: $red;
  background: transparent;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  @include fontrem(1.6);

  @media ($phablet) {
    width: auto;
    padding: 5px 20px;
    @include fontrem(1.4);
  }

  @media ($tablet-wide) {
    padding: 5px 10px;
  }

  &:hover,
  &:focus {
    color: $white;
    background: $red;
    border-color: darken($red, 10%);
  }

  &:active {
    background: darken($red, 10%);
  }

  &-red {
    color: $white;
    background: $red;
    border-color: darken($red, 10%);

    &:hover,
    &:focus {
      background: darken($red, 10%);
      border-color: darken($red, 15%);
    }

    &:active {
      background: darken($red, 15%);
    }
  } // red

  &-blue {
    color: $white;
    background: $blue;
    border-color: darken($blue, 10%);

    &:hover,
    &:focus {
      background: darken($blue, 10%);
      border-color: darken($blue, 15%);
    }

    &:active {
      background: darken($blue, 15%);
    }
  } // blue

} // btn

// Weblink register button
.wc-eventregisterbutton {
  @extend .btn-red;
}

// Page Styles

.page-container {
  background: $white;
  max-width: $page-container-max-width;
  margin: 0 auto;
}

.page-content {
  clear: both;
  padding: 0 2rem;

  @media ($tablet-wide) {
    padding: 0 4rem;
  }

  &.no-padding {
    @media ($tablet-wide) {
      padding: 0;

      p {
        margin-top: 0;
      }
    }
  }

  .section-col:not(:last-of-type) {

    @media ($tablet) {
      padding-right: 2em;
    }

    @media ($tablet-wide) {
      padding-right: 4em;
    }
  }
}

.page-title {
  margin: 0 auto 1em;
  text-align: center;
  @include fontrem(4);

  @media ($tablet) {
    margin-bottom: 5px;
    text-align: left;
  }

  @media ($tablet-wide) {
    @include fontrem(5);
  }
}

// Full width hero image and text
.fullwidth-hero {
  position: relative;
  width: 100%;
  height: 150px;

  @media ($tablet) {
    height: 300px;
  }

  &.homepage {
    height: 500px;
  }

  &.has-hero-text {
    height: auto;
  }

  &-bg {
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 150px;
  }

  &-overlay {
    width: 100%;
    height: 100%;
    background: rgba($black, 0.5);

    &.homepage {
      position: absolute;
      top: 0;
      background: rgba($black, 0.15);
      z-index: 2;
    }

    @media ($tablet) {
      min-height: 300px;
    }
  }

  &-text {
    color: rgba($white, 0.8);
    font-family: $headerFont;
    margin: 0 auto 10px;
    padding: 20px;
    text-shadow: 1px 1px 1px $black;
    text-transform: uppercase;
    line-height: 1;
    @include fontrem(4);

    @media ($tablet) {
      top: 5%;
      transform: none;
      padding: 20px 0 0 8%;
      text-align: left;
      @include fontrem(4.5);
    }

    @media ($tablet-wide) {
      @include fontrem(6);
    }

    &.homepage {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      padding: 0 8%;
      text-align: center;

      @media ($tablet) {
        position: absolute;
        left: 0;
        width: 55%;
        text-align: left;
      }

      @media ($tablet-wide) {
        padding-left: 8%;
      }
    }

    & > a {
      color: inherit;
      text-decoration: none;
      transition: color 0.2s;

      &:hover,
      &:focus {
        color: $white;
      }
    } // > a
  } // .fullwidth-hero-text

  .fullwidth-hero-content {
    color: $white;
    text-shadow: 1px 1px 1px $black;
    padding: 0 20px;
    @include fontrem(1.4);

    @media ($tablet) {
      padding: 0 8%;
      @include fontrem(1.6);
    }

    h5 {
      color: $white;
      font-family: $subHeaderFont;
      font-weight: bold;
      margin: 0;
      @include fontrem(1.8);
    }

    p {
      margin-top: 0;
    }

    a {
      color: $white;
      text-decoration: underline;
    }

  } // .fullwidth-hero-content

  .video-bg {
    position: relative; /* contain the abs-pos <video> */
    width: 100%;
    height: 100%;
    overflow: hidden; /* prevent scrollbars */
    z-index: 1;

    video {
      display: none;
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%); /* keep centered in parent */
      min-width: 100%; /* always cover horizontal */
      min-height: 100%; /* always cover vertical */
      z-index: -1;

      @media ($tablet) {
        display: block;
        top: 60%;
      }
    }
  }

} // .fullwidth-hero

.section-col-container {
  position: relative;
  width: 100%;

  .section-col {

    @media ($tablet) {
      float: left;
    }

    .section-col-main {
     padding-left: 20px;
    }

  } // .section-column

  //&.display-table {
  //  @media ($tablet-wide) {
  //    display: table-row;
  //
  //    > .section-col {
  //      float: none;
  //      display: table-cell;
  //    }
  //  }
  //} // .display-table

} // .section-column-container

// COLUMN SIZES

.col-25 {

  @media ($tablet) {
    width: 25%;
  }
}

.col-33 {

  @media ($tablet) {
    width: 33.3333%;
  }
}

.col-50 {

  @media ($tablet) {
    width: 50%;
  }
}

.col-66 {

  @media ($tablet) {
    width: 66.6666%;
  }
}

.col-75 {

  @media ($tablet) {
    width: 75%;
  }
}

.rtl {

  @media ($tablet) {
    float: right !important; // to override .section-col float
  }
}

// Gray mini-menu between header and page content:

.mini-nav-block-container {
  display: none; // takes up too much space on mobile, redundant to footer menu anyway.
  background: $lightGray;
  border-bottom: 2px solid $darkBlue;

  @media ($phablet) {
    display: block;
  }
}

// Meet the Team
.meet-the-team {
  height: 125px;
  margin-top: 2em;
  background: rgba($blue, 0.8);

  .col-25 {
    display: none; // only show .col-50 on mobile

    @media ($tablet) {
      display: block;
    }
  }

  .meet-the-team-col {
    position: relative;
    height: 100%;
    background: $darkBlue;
  }

  h5 {
    color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    @include fontrem(4);

    @media ($tablet) {
      @include fontrem(3.5);
    }

    @media ($tablet-wide) {
      @include fontrem(4);
    }
  }

  .banner-link {
    display: block;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 514px 128px;

    .overlay {
      width: 100%;
      height: 100%;
      background: rgba($black, 0.5);
      transition: background 0.2s ease-in-out;
    }

    &:hover .overlay {
      background: rgba($darkBlue, 0.8);
    }
  } // .banner-link

} // .meet-the-team

// Block Links
.block-links {
  margin-top: 2em;
  padding-bottom: 2em;

  .block-link {
    width: 100%;
    //height: 80px;
    background: $blue;
    transition: background 0.2s ease-in-out;

    &:not(:last-child) {
      margin: 0 auto 2rem;

      @media ($phablet) {
        margin: 0 2rem 2rem auto;
      }
    }

    @media ($tablet) {
      float: left;
      width: auto;
      min-width: 300px;
      max-width: 500px;
    }

    &:hover {
      background: darken($blue, 10%);
    }

    &.red {
      background: darken($red, 20%);

      &:hover {
        background: darken($red, 30%);
      }
    }

    &.dark-blue {
      background: $darkBlue;

      &:hover {
        background: darken($darkBlue, 10%);
      }
    }

    a {
      text-decoration: none;
      display: flex;
      flex-direction: row;
    }

    &-image {
      width: 33.333%;
    }

    &-title {
      position: relative;

      h5 {
        margin: 0;
        padding: 0.5em 0.5em 0.5em 0.5em;
        color: $white;
        line-height: 1;
        @include fontrem(2);

        @media ($tablet) {
          @include fontrem(2.6);
        }
      }
    }

  } // .block-link

} // .block-links

.block-text-link {
  max-width: 500px;
  background: $blue;
  margin: 0 0 2rem;
  font-family: $headerFont;
  color: $white;
  transition: background 0.2s ease-in-out;
  @include fontrem(2.6);

  @media ($tablet) {
    min-width: 300px;
    float: left;
    margin-right: 2rem;
  }

  @media (min-width: 1366px) {
    &:nth-of-type(4) {
      clear: left;
    }
  }

  &:hover,
  &:focus {
    background: darken($blue, 10%);
  }

  &.red {
    background: $red;

    &:hover,
    &:focus {
      background: darken($red, 10%);
    }
  }

  &.dark-blue {
    background: $darkBlue;

    &:hover,
    &:focus {
      background: darken($darkBlue, 10%);
    }
  }

  &.dark-gray {
    background: $darkGray;

    &:hover,
    &:focus {
      background: darken($darkGray, 10%);
    }
  }

  a {
    display: block;
    padding: 2rem;
    color: inherit;
    text-decoration: none;
  }
}

// Content sections
.section-header {
  padding: 0 0 0.2em 15px;
  line-height: 1;
  border-bottom: 4px solid $blue;
  @include fontrem(4.5);

  @media ($tablet) {
    padding-left: 30px;
  }

  small {
    @include fontrem(3);
  }

  &.text-red {
    border-color: $red;
  }
} // section-header

.blog-header {
  margin: 0 auto;
  border-bottom: 0;
}

p.blog-date {
  padding-left: 15px;
  margin: 1em 0 0 0 !important;
  font-family: $headerFont;
  color: $darkGray;
  @include fontrem(2);

  @media ($tablet) {
    padding-left: 30px;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
      color: $red;
    }
  } // a
} // blog-date

.blog-byline {
  margin: 0 auto;
  padding: 0 0 1rem 1.5rem;
  @include fontrem(1.4);

  @media ($tablet) {
    padding-left: 3rem;
  }
} // blog-byline

.blog-categories {
  margin: 0 auto 1rem;
  padding: 0.5em 1.5rem 0;
  @include fontrem(1.2);

  @media ($tablet) {
    padding-left: 3rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 0.5rem 1rem 0;
  }

  a {
    display: block;
    background: $lightGray;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    color: $black;
    text-decoration: none;
    transition: all 0.2s;

    &:hover,
    &:focus {
      background: $blue;
      color: $white;
    }
  } // a
} // blog-categories

.section-body {
  padding-left: 15px;
  margin-bottom: 4rem;

  @media ($tablet) {
    padding-left: 30px;
  }

  h2 {
    color: $red;
    border-bottom: 2px solid $red;
    @include fontrem(3.5);
  }

  &.no-padding {
    padding-right: 2rem;
    padding-left: 0;
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  iframe {
    width: 100%;
    max-width: 100% !important;
    border: none !important;
  }
} // section-body

// post & page header-styles
.section-body,
.page-content {

  p {
    margin-top: 0;
  }

  h3,
  h4,
  h5,
  h6 {
    margin-top: 0.25em;
    margin-bottom: 0;
  }

  h3 {
    @include fontrem(3);
  }

  h4 {
    color: $black;
    @include fontrem(2.25);
  }

  h5 {
    color: $black;
    @include fontrem(1.8);
  }

  h6 {
    font-family: $bodyFont;
    font-weight: bold;
    color: $darkGray;
    @include fontrem(1.4);
  }

} // header-styles

.blog-body {
  border-top: 4px solid $blue;
  padding-top: 3rem;
}

// Checkboxes
.checkbox {
  position: relative;
  height: 20px;
  margin: 10px 0;

  label {
    cursor: pointer;
    padding-left: 5px;

    &:before, &:after {
      content: "";
      position: absolute;
      left:0;
      top: 0;
    }

    &:before {
      width: 20px;
      height: 20px;
      background: $white;
      border: 2px solid $red;
      cursor: pointer;
      transition: background 0.2s ease-in-out;
    }

  }

  input[type="checkbox"] {
    outline: 0;
    margin-right: 10px;

    &:checked {

      + label:before{
        background: $red;
        border: none;
      }

      + label:after {
        transform: rotate(-45deg);
        top: 5px;
        left: 4px;
        width: 12px;
        height: 6px;
        border: 2px solid $white;
        border-top-style: none;
        border-right-style: none;
      }
    }
  }

} // .checkbox

input[type="search"] {
  -webkit-appearance: none;
  border-radius: 0;
}

.video-container {
  position: relative; // contain abs-pos child
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; // make height proportional to width, maintaining video aspect ratio
  margin: 0 auto;
  text-align: center;

  @media ($laptop) {
    padding-bottom: 0; // undo proportional height once video hits full-size
  }

  iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
} // modal-video-container

// Content with image - float image left
.float-image {
  margin-bottom: 2rem;

  p {
    @media ($tablet) {
      float: left;
      width: 80%;
    }
  }

  > img,
  > a:first-child {
    //width: 100px !important;
    max-width: 100%;

    @media ($tablet) {
      float: left;
      width: 30%;
      padding-right: 2rem;
      max-width: 100px !important;
    }
  }

} // .float-image

// Description Lists
dl {
  text-align: center;

  @media ($tablet) {
    text-align: left;
  }

  &.no-indent {
    text-align: left;

    dt,
    dd {
      margin-left: 0;
    }
  }

  dt {
    margin-top: 1rem;
    font-family: $headerFont;
    @include fontrem(2);

    &:first-of-type {
      margin-top: 0;
    }
  }

  dd {
    margin-left: 0;

    @media ($tablet) {
      margin-left: 20px;
    }
  }
}

.attention-header {
  color: $black;
  border-bottom: 2px solid $red;
  padding-bottom: 0.125em;
  margin-bottom: 0.125em !important;
}

.homepage-recent-post {
  margin: 3rem 0;

  h3 {
    margin-top: 1em;
    @include fontrem(2.5);

    &.homepage-recent-post-title {
      @include fontrem(3);
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  h2 {
    @include fontrem(3.3);
  }

  h4,
  h5,
  h6 {
    @include fontrem(2);
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
} // .homepage-recent-post

.single-arrows {
  @extend .cf;
  margin-top: 3em;

  a {
    color: $blue;

    &:hover,
    &:focus {
      color: darken($blue, 10%);
    }
  } // a
} // single-arrows

.single-prev-post {
  float: left;
}

.single-next-post {
  float: right;
}

.alignleft {
  display: block;
  margin: 0 auto;
  padding-bottom: 2em;

  @media ($tablet) {
    padding-right: 1em;
    padding-bottom: 1em;
    float: left;
  }

}

.alignright {
  display: block;
  margin: 0 auto;
  padding-bottom: 2em;

  @media ($tablet) {
    padding-left: 1em;
    padding-bottom: 1em;
    float: right;
  }

}

.aligncenter {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.read-more-link {
  margin: 1em 0;
}
