//
// PAGE-GLIP.PHP
// ------------------------------

.glip-hero-container {

  h5 {
    color: $white;
    margin: 0;
    @include fontrem(3.5);
  }

  .mission-block {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 480px;
    min-height: 240px;
    padding: 2rem 4rem;
    background: $red;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    text-overflow: ellipsis;

    @media ($tablet) {
      height: 21vw;
    }

    @media ($page-container-max) {
      height: 480px;
    }

    p {
      color: $white;
      text-transform: uppercase;
      @include fontrem(1.2);

      @media ($laptop) {
        @include fontrem(1.4);
      }
    } // p

  } // .mission-block

  .possible-block {
    position: relative;
    color: $white;
    height: auto;
    background: $red;
    padding: 1rem 2rem;
    max-height: 480px;
    min-height: 300px;

    @media ($tablet) {
      height: 21vw;
      min-height: 240px;
      padding: 1.5rem;
    }

    @media ($page-container-max) {
      height: 480px;
    }

    .possible-content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    h5 {
      font-size: 10vw;
      text-align: center;
      margin-bottom: 20px;
      line-height: 1;

      @media ($tablet) {
        font-size: 3vw;
        text-align: left;
        margin-bottom: 0;
      }

      @media ($page-container-max) {
        @include fontrem(5.6);
      }
    }

    .block-subtext {
      font-family: $headerFont;
      color: $white;
      margin: 0;
      text-align: center;
      @include fontrem(2.5);

      @media ($tablet) {
        text-align: left;
        @include fontrem(1.8);
      }

      @media ($laptop) {
        @include fontrem(2);
      }
    }

    img {
      max-width: 35%;
      display: block;
      margin: 0 auto;

      @media ($phablet) {
        max-width: 15%;
      }

      @media ($tablet) {
        max-width: 50%;
      }

      @media ($tablet-wide) {
        max-width: 55%;
      }

    } // img

  } // .possible-block

  .video-container {
    max-height: 480px;
    min-height: 240px;

    @media ($laptop) {
      height: 21vw;
    }

    @media ($page-container-max) {
      height: 480px;
      padding-bottom: 0;
    }
  }

  .country-table {
    width: 100%;
    height: auto;
    max-height: 480px;

    @media ($tablet) {
      height: 13vw;
      min-height: 240px;
    }

    @media ($page-container-max) {
      height: 480px;
    }

    .table-header {
      color: $white;
      font-family: $headerFont;
      text-transform: uppercase;
      background: $blue;
      height: 50px;
      padding: 10px 10px 10px 20px;
      @include fontrem(2);
    }

    .country-list {
      list-style: none;
      margin: 0;
      padding: 0;
      overflow-y: auto;
      height: 260px;

      @media ($tablet) {
        height: calc(100% - 50px);
      }

      @media ($page-container-max) {
        height: 278px;
      }

      a {
        position: relative;
        color: $black;
        display: block;
        padding: 10px 20px 10px 20px;
        background: $white;
        text-decoration: none;
        border-bottom: 1px solid $lightGray;
        transition: background 0.2s ease-in-out;
        @include fontrem(1.4);

        &:hover {
          background: $lightGray;
        }

        .fa {
          position: absolute;
          color: $blue;
          top: 50%;
          right: 40px;
          transform: translateY(-50%);
        }
      }

    } // .country-list

  } // .country-table

  .multiple-block-container {
    height: auto;
    max-height: 480px;

    @media ($phablet) {
      width: 100%;
    }

    @media ($tablet) {
      width: 25%;
      height: 21vw;
    }

    @media ($page-container-max) {

    }

    .nav-block {
      max-height: 240px;

      @media ($tablet) {
        height: calc(21vw / 2);
        min-height: 120px;
      }

      @media ($page-container-max) {
        height: 240px;
      }
    }
  } // .multiple-block-container

} // ..glip-hero-container
