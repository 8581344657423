header {
  background: $blue;
  color: $white;
  border-bottom: 2px solid $darkBlue;
  position: relative;
  z-index: 10000001;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: $white !important;
    text-decoration: none;
  }
  @media ($laptop) {
    position: fixed;
    width: 100%;
  }
} // header

.site-identity {
  float: left;
  padding: 3rem 0 2rem 2.5rem;

  img,
  .svg {
    width: 200px;
  }

  .svg {
    max-height: 70px; // For IE
  }

  @media (min-width: 600px) {
    padding-bottom: 3rem;

    img,
    .svg {
      width: 240px;
    }
  } // @media

  @media ($tablet-wide) {
    img,
    .svg {
      width: 300px;
    }
  } // @media

  .svg svg {
    width: 100%;
  }
} // site-identity

.hamburger-icon {
  float: right;
  display: block;
  width: 60px;
  height: 60px;
  margin: 2rem 1rem 0 0;
  padding-top: 20px;
  text-align: center;
  text-decoration: none;
  font-size: 50px;
  line-height: 0;

  @media (min-width: 600px) {
    margin-left: 1.5rem; // match side-margin on header-menu li's
  }

  @media ($tablet-wide) {
    margin-top: 3.6rem;
  }

  .line {
    display: block;
    height: 3px;
    width: 30px;
    margin: 0 auto 5px;
    background: $white;
  }

  &:hover,
  &:focus {
    background: $white;

    .line {
      background: $blue;
    }
  } // hover/focus
} // hamburger-icon

.hamburger-icon,
.hamburger-icon .line,
.close-icon {
  transition: all 0.2s ease-in-out;
}

.header-menu {
  float: right;
  margin: 3.5rem auto 0;
  font-family: $headerFont;

  @media (max-width: 599px) {
    float: none;
    clear: both;
    width: 100%;
    margin: 0 auto 2rem;
    text-align: center;
  }

  @media ($tablet-wide) {
    margin-top: 4.5rem;
  }

  nav {
    display: inline-block;
    vertical-align: top;
  }

  ul {
    text-align: center;
  }

  li {
    display: inline-block;
    margin: 0 1.5rem;
    @include fontrem(2);

    @media ($tablet-wide) {
      @include fontrem(3);
    }
  } // li

  a:hover,
  a:focus {
    color: $lightGray;
  }
} // header-menu

.header-search-icon {
  display: inline-block;
  padding-left: 1rem;
  min-width: 2.4rem;

  @media ($tablet-wide) {
    min-width: 2.8rem;
  }

  .fa {
    position: relative;
    top: 0.2em;
    cursor: pointer;
    transition: color 0.2s;

    @media ($tablet-wide) {
      top: 0.4em;
      @include fontrem(2);
    }

    &:hover,
    &:focus {
      color: $lightGray;
    }
  } // fa
} // header-search-icon

.side-navigation-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000002; // keep atop everything
  width: 100%;
  height: 100%;
  background: $darkBlue;
  color: $white;
  border-left: 4px solid $red;
  transform: translateX(100%);
  transition: all 0.3s ease-out;

  @media ($tablet) {
    width: 600px;
  }

  &.is-open {
    transform: translate(0, 0);
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  li {
    display: block;
    margin-top: 1.5rem;
    font-family: $headerFont;
    @include fontrem(3);
  }

  ul ul {
    padding-left: 1.5rem;

    li {
      margin: 0 auto 0.6rem;
      font-family: $bodyFont;
      @include fontrem(1.4);

      @media ($tablet) {
        @include fontrem(1.6);
      }
    } // li
  } // ul ul
} // side-navigation-menu

.side-nav-inner {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 3rem 2.1rem;
}

.side-nav-home-link {
  display: block;
  width: 200px;

  img,
  svg {
    max-width: 100%;
  }

  svg {
    max-height: 46px; // For IE
  }
} // side-nav-home-link

.close-icon {
  position: absolute;
  top: 2rem;
  right: 1rem;
  display: block;
  width: 60px;
  height: 60px;
  padding-top: 30px;
  text-align: center;
  text-decoration: none;
  font-size: 50px;
  line-height: 0;

  @media ($tablet) {
    right: 3rem;
  }

  @media ($tablet-wide) {
    top: 3.5rem;
  }

  &:hover,
  &:focus {
    color: $darkBlue;
    background: $white;
  }
} // close-icon

.side-nav-searchbar {
  margin: 2em auto 0;
} // side-nav-searchbar

.search-field {
  width: 50%;
  height: 36px;
  border: 1px solid $black;
  padding: 5px;
  font: inherit;

  @media ($phablet) {
    width: 70%;
    max-width: 280px;
  }

  @media ($tablet) {
    width: 60%;
  }
} // search-field

.search-submit {
  display: inline-block;
  height: 36px;
  padding: 6px 10px;
  border: 2px solid darken($red, 10%);
  background: $red;
  color: $white;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  @include fontrem(1.4);

  &:hover,
  &:focus {
    background: darken($red, 10%);
    border-color: darken($red, 15%);
  }
} // search-submit

.side-nav-lower-menu {
  font-family: $headerFont;
  margin-top: 3rem;
  color: $white;
  @include fontrem(2.5);

  p {
    margin: 0 auto 1rem;
    padding: 0;
  }
} // side-nav-lower-menu
