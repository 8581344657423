.social-bar {

  @media ($tablet) {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    float: left;
    width: 45px;
    height: 45px;
    color: $white;
    text-align: center;
    transition: all 0.2s ease-out;

    @media ($tablet) {
      clear: both;

      &:hover,
      &:focus {
        width: 120px;
      }
    } // @media

    a {
      display: block;
      height: 100%;
      padding: 1rem 0 0;
      color: inherit;
      line-height: 1;
      @include fontrem(2.4);

      &.email { background: $lightGray; }
      &.fb { background: #3b5998; }
      &.tw { background: #1da1f2; }
      &.ig { background: #000; }
      &.li { background: #0077b5; }
      &.vm { background: #1ab7ea; }
      &.gp { background: #ea4335; }
    } // a
  } // li
} // social-bar

.heateor_sss_vertical_sharing {

  @media ($under-tablet) {
    position: static !important; // override plugin style
  }

  @media ($tablet) {
    top: 50% !important; // override plugin style
    transform: translateY(-50%);
  }
}
