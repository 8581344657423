footer {
  background: $blue;
  padding: 4rem 1rem;
  color: $white;
  text-align: center;
  border-top: 2px solid darken($darkBlue, 5%);
  position: relative;
  z-index: 10000001;

  @media ($phablet) {
    text-align: left;
  }

  @media ($laptop) {
    padding: 4rem 0;
  }

  .row {

    @media ($phablet) and ($under-tablet-wide) {
      float: left;
      width: 50%;
    }

    @media ($phablet) and ($under-laptop) {
      @include fontrem(1.4);
    }

    &.reverse-order {
      @media ($tablet-wide) {
        padding: 0 4rem;

        & > .col {
          float: right;
        }
      } // @media

      @media ($laptop) {
        padding: 0;
      }
    } // reverse-order
  } // row

  .col {
    @media ($tablet-wide) {
      float: left;
    }
  } // col

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  a {
    color: $white !important;
    text-decoration: none; // remove underlines from all nav links
    transition: color 0.2s;

    &:hover,
    &:focus {
      color: $lightGray;
    }
  } // a
} // footer

.footer-logo {
  padding: 0 2rem 3rem;

  @media ($tablet-wide) and ($under-laptop) {
    float: none;
    width: 100%;
    text-align: center;
    padding-bottom: 4rem;
  }

  @media ($laptop) {
    width: 25%;
    padding-left: 4rem;
  }

  img,
  .svg {
    width: 200px;
  }

  .svg {
    margin: 0;
    max-height: 47px; // For IE

    @media ($under-phablet) {
      margin: 0 auto;
    }
  }

  .svg svg {
    width: 100%;
  }
} // footer-logo

.footer-menu-main {
  margin-bottom: 2.5em;

  @media ($tablet-wide) and ($under-laptop) {
    width: 100%;
    text-align: center;
    padding-left: 4rem; // match right margin of li's
  }

  @media ($laptop) {
    width: 75%;
  }

  li {
    display: block;
    margin-bottom: 1.5em;

    @media ($phablet) and ($under-tablet-wide) {
      margin-left: 2rem;
    }

    @media ($tablet-wide) {
      display: inline-block;
      margin-right: 4rem;
    }

    a {
      font-family: $headerFont;
      line-height: 1;
      @include fontrem(2.5);

      span {
        display: block;
        font-family: $bodyFont;
        @include fontrem(1.4);
      }
    } // a
  } // li
} // footer-menu-main

.footer-subhead {
  font-family: $headerFont;
  line-height: 1;
  margin: 0 auto 1em;
  @include fontrem(2.5);
} // footer-subhead

.footer-menu-quicklinks {

  li {
    display: block;
    margin-bottom: 0.6rem;

    a {
      line-height: 1;
    } // a
  }
} // footer-menu-quicklinks

.footer-links,
.footer-contact {
  margin-bottom: 2em;

  p:not(.footer-subhead) {
    margin: 0 auto;
  }
} // footer-links/contact

.footer-links {
  @media ($tablet-wide) {
    width: 75%;
  } // @media

  @media ($tablet-wide) and ($under-laptop) {
    .footer-subhead {
      padding-left: 2rem;
    }

    ul {
      float: left;
      width: 33.3333%;
      padding-left: 2rem;
    }
  } // @media

  @media ($laptop) {
    padding-right: 4rem;

    ul {
      display: inline-block;
      vertical-align: top;
      margin-right: 6.6rem;
    }
  } // @media
} // footer-links

.footer-contact {
  @media ($tablet-wide) {
    width: 25%;
  }

  @media ($laptop) {
    padding-left: 4rem;
  }
} // footer-contact

.footer-icons {

  @media ($tablet-wide) {
    clear: both;
    width: 100%;
    text-align: center;
  }

  @media ($laptop) {
    text-align: right;
    padding-right: 4rem;
  }

  li {
    display: inline;
    margin: 0 1rem;

    a {
      @include fontrem(2.5);
    }
  } // li
} // footer-icons
