.event-feed-tabs-container {
  margin: 1em auto 2rem;

  .event-feed-tabs {
    position: relative;
    z-index: 2; // to go on top of scroll-container
    list-style: none;
    padding: 0;
    margin: 0 0 1rem 0;

    @media ($tablet) {
      margin: 0;
    }

    li {
      display: block;
      text-align: center;

      @media ($tablet) {
        float: left;
        text-align: left;
      }

      a {
        color: lighten($black, 50%);
        font-family: $headerFont;
        display: block;
        padding: 0.5rem 1.5rem;
        background: transparent;
        text-decoration: none;
        @include fontrem(2.5);

        @media ($tablet-wide) {
          padding: 1rem 3rem;
          @include fontrem(3);
        }

        &:hover {
          color: $black;
        }
      }

      &.active {

        a {
          color: $black;
          background: $white;
          border: 2px solid $blue;

          @media ($tablet) {
            border-top: 2px solid $blue;
            border-right: 2px solid $blue;
            border-left: 2px solid $blue;
            border-bottom: none;
          }

        } // a
      } // &.active

    }
  } // .event-feed-tabs

  .event-feed-scroll-container {
    background: $white;
    border: 2px solid $blue;
    overflow: auto;
    height: 400px;
    padding: 0 3rem;

    @media ($tablet) {
      position: relative;
      top: -2px; // To position under the .event-feed-tabs
      height: 600px;
    }
  }

  .event-feed-item {
    width: 100%;
    padding: 2rem 0;
    border-bottom: 1px solid $red;

    &:last-of-type {
      border-bottom: none;
    }

    @media ($tablet) {
      display: table;
    }

    .event-feed-date,
    .event-feed-time {
      color: $black;
      font-family: $headerFont;
      white-space: nowrap;
      @include fontrem(1.8);
    } // .event-feed-date

    .event-feed-time {
      color: lighten($black, 50%);
      padding-left: 1rem;
    }

    .event-register-button {
      display: block;
      margin: 0 auto;

      @media ($tablet) {
        display: table-cell;
        vertical-align: top;
        min-width: 160px;
        text-align: right;
      }

      .btn {
        padding: 5px 10px;
      }

    } // .event-register-button

    .event-details {

      @media ($tablet) {
        display: table-cell;
        vertical-align: top;
        padding-right: 2rem;
      }

      h5 {
        color: $red;
        margin-top: 0;
        @include fontrem(2.3);

        a {
          color: $red;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      p {
        margin-top: 0.5em;
        @include fontrem(1.6);

        &:last-child {
          margin-bottom: 0;
        }
      }

      .event-location {
        display: block;
        color: $darkGray;
      }
    } // .event-details

  } // .event-feed-item

  .gli-events-feed,
  .partner-events-feed {
    display: none;

    &.active {
      display: block;
    }
  }

  .partner-events-feed .event-feed-item .event-details h5 {
    color: $blue;
  }

} // .event-feed-tabs-container
