//
// TABLE STYLES (AND TABLE NAV COLUMN)
// ------------------------------
.table-nav-col {
  background: $darkBlue;
  border-top: 4px solid $red;
  width: 100%;

  @media ($tablet) {
    width: 33.3333%;
  }

  @media ($tablet-wide) {
    width: 25%;
  }
} //.table-nav-column

.business-table-col {
  width: 100%;
  padding: 0 1em;

  @media ($tablet) {
    width: 66.6666%;
  }

  @media ($tablet-wide) {
    width: 75%;
  }
} // .business-table-col

.table-info-label {
  color: $lightGray;
  text-align: center;
  @include fontrem(1.2);
}

.table-data-header {
  color: $red;
  font-family: $bodyFont;
  text-transform: none;
  margin: 2.5em auto 0.5em;
  @include fontrem(2);

  &:first-of-type {
    margin-top: 0;
  }
}

.table-tab-nav {
  width: 100%;
  padding: 0;
  list-style: none;

  .table-tab-header {

    span {
      color: $white;
      text-transform: uppercase;
      @incldue fontrem(2.4);

      &.add-padding {
        padding: 10px;

        @media ($tablet-wide) {
          padding: 10px 0 10px 40px;
        }

      }
    }

    &:not(:first-child) {
      padding: 10px;

      @media ($tablet-wide) {
        padding: 10px 0 10px 40px;
      }
    }

    a {
      color: $white;
      text-decoration: none;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $lightGray;
      }
    }

  }

  .table-tabs {
    width: 100%;
    padding: 0;

    li {
      width: 100%;
      padding: 0;
      list-style: none;
      text-transform: none;
      border-bottom: 1px solid $red;
      @include fontrem(1.6);

      a {
        display: block;
        color: $white;
        text-decoration: none;
        padding: 10px 20px;
        @include fontrem(1.4);

        @media ($tablet) {
          padding: 10px;
        }

        @media ($tablet-wide) {
          padding: 10px 10px 10px 60px;
        }

        &:hover {
          background: $red;
        }
      }

      &.active {
        background: $red;
      }

    }
  } //.table-tabs

} // .table-tab-nav

// TABLEPRESS CUSTOM STYLES
.tablepress {
  width: 100% !important;
}

.gli-tablepress {
  overflow-x: auto; // allow horizontal scrolling
}

.gli-tablepress .tablepress,
body .tablepress {
  width: 100%;
  color: $blue;
  font-family: $bodyFont;
  border-bottom: 2px solid $red;
  @include fontrem(1.4);

  @media ($tablet) {

  }

  thead th {
    background: $white;
    line-height: 1;
    text-transform: uppercase;
    border-bottom: 2px solid $red;
    @include fontrem(1.4);

    small {
      @include fontrem(1.2);
    }

    &:hover {
      background: $white;
    }
  }

  th,
  td {
    padding: 8px 8px 8px 0;
  }

  strong {
    text-transform: uppercase;
    font-weight: 700;
  }

} // .gli-tablepress-table

// TABS
.business-table-tab {
  display: none;

  &.active {
    display: block;
  }
}

.footnote {
  color: $darkBlue;
  margin: 0 auto 1.5em;
  text-align: center;
  @include fontrem(1.1);

  @media ($tablet) {
    margin: 0 auto 2em;
    text-align: left;
  }
}

//
// MEET THE TEAM TABLE
// ========================
.table-meet-the-team {

  tbody td {
    display: block;
    text-align: center;

    @media($tablet) {
      display: table-cell;
      text-align: left;
      vertical-align: middle !important;
    }

    &.column-1 strong {
      color: $black;
    }

    .btn {
      margin-top: 0.5em;
    }

    &.column-2 {

      @media ($under-tablet) {
        border-top: none !important;
      }

    }
  }

} // .table-meet-the-team
