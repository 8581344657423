//
// EVENTS
// ------------------------------
.event-item {
  margin-bottom: 3em;
  padding-left: 1em;
  padding-bottom: 2rem;
  border-bottom: 1px solid $lightGray;
  @include fontrem(1.6);

  @media ($tablet) {
    padding-left: 2em;
    padding-bottom: 4rem;
  }

  .event-title {
    font-family: $headerFont;
    margin: 0;
  }

  .event-details span {
    color: $darkGray;
    display: block;

    @media ($tablet) {
      display: inline;
      margin-right: 1.5em;
    }

  } // .event-details span

  .event-location {
    display: inline-block;
    color: $darkGray;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .event-description {
    margin-top: 1rem;
    @include fontrem(1.5);
  }

  .event-sponsor-img {
    margin-top: 2em;

    @media ($tablet) {
      margin-top: 0;
    }

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }

  }


} //.event-item

.gli-events {

  .event-title {
    color: $red;
    @include fontrem(2.8);

    &.text-blue {
      color: $blue;
    }

    a {
      text-decoration: none !important;
      color: $red !important;
    }
  } // .event-title

  .event-feed-time {
    padding-left: 1rem;
  }

} // .gli-events

.partner-events {

  .event-title {
    font-family: $sans;
    font-weight: 700;
    @include fontrem(1.6);
  }

  .event-item {
    margin-bottom: 2em;
  }

  .event-details {
    margin-bottom: 0.5em;
    @include fontrem(1.6);

    span {
      display: block;
    }

    .event-location:hover {
      text-decoration: none;
    }

  }

} // .partner-events

.register-phone {
  margin: 1rem auto 2rem;

  @media ($tablet) {
    display: none;
  }
}


.register-tablet {
  display: none;
  margin: 1rem auto;

  @media ($tablet) {
    display: inline-block;
  }
}

.presenting-sponsor-header {
  color: darken($lightGray, 10%) !important;
  text-align: center;
  margin-bottom: 1rem !important;
}
