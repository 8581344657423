body.modal-open {
  overflow: hidden !important;
}

.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000004;
  background: rgba($black, 0.6);

  &.modal-open {
    display: block;
  }
} // .modal-overlay

.modal {
  display: none;
  width: 600px;
  max-width: 100%;
  height: 460px;
  max-height: 100%;
  position: fixed;
  z-index: 10000005;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $white;

  @media ($tablet) {
    height: 360px;
  }

  &.modal-open {
    display: block;
  }

  .modal-close {
    float: right;
    position: relative;
    right: 20px;
    top: 15px;
    z-index: 10000004;
    width: 40px;
    height: 40px;
    text-align: center;
    color: $blue;
    font-family: $sans; // don't use a webfont here
    line-height: 0;
    padding-top: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    @include fontrem(5);

    @media ($tablet) {
      width: 60px;
      height: 60px;
      padding-top: 30px;
      @include fontrem(6);
    }

    &:hover,
    &:focus {
      color: $white;
      background: $blue;
    }

    .modal-content & {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      padding: 0;
      color: $black;
      text-shadow: none;
      line-height: 1;
      @include fontrem(3);
    }
  } // modal-close

  .modal-body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 20px 50px 20px 40px;

    h5 {
      margin: 0 0 1em;
      color: $blue;
      line-height: 1;
      @include fontrem(3.2);

      @media ($tablet) {
        margin: 10px 0 1em;
      }
    }

    p {
      margin-top: 0;
    }
  } // .modal-body

} // .modal
