/*!
Theme Name: GLI 2017
Author: PriceWeber
Author URI: https://www.priceweber.com/
Description: Custom theme developed by PriceWeber for Greater Louisville Inc. in 2017.
Version: 1.0
Note: This theme uses Sass and Gulp. Learn more at http://sass-lang.com/ and http://gulpjs.com/.

DO NOT edit this style.css file. Only make changes to the SCSS partials.

*/
