.page-template-page-advocacy {

  .section-body {
    margin: 2rem auto;
  }

  .media-post {

    h2 {
      border-bottom: none;
      font-size: 2.8rem;
    }

  }
} // .page-template-page-advocacy

.block-links-container {
  width: 100%;
  margin: 4rem auto;

  .block-link-item {
    width: 100%;
    margin: 2rem auto;

    @media ($phablet) {
      float: left;
      width: 50%;

      &:nth-child(2n+1) {
        clear: left;
      }
    }

    @media ($tablet-wide) {
      width: 33.3333%;

      &:nth-child(2n+1) {
        clear: none;
      }

      &:nth-child(3n+1) {
        clear: left;
      }
    }

    .block-link-inner {
      padding: 0 2rem;

      .block-link-image {
        display: block;
        width: 100%;
        margin: 0 auto;

        img {
          width: 100%;
          max-width: 100%;
          margin: 0 auto;

          @media ($tablet) {
            opacity: 0.9;
            transition: opacity 0.2s ease-in-out;
          }
        }
      } // .block-link-image

      .block-link-header {
        display: block;
        margin: 0 auto;

        a {
          display: block;
          color: $white;
          background: $blue;
          font-family: $headerFont;
          height: 100%;
          margin: 0 auto;
          padding: 10px;
          text-transform: uppercase;
          text-decoration: none;
          transition: background 0.2s ease-in-out;
          @include fontrem(3);
        }
      } // .block-link-header

      .block-link-snippet {
        color: $black;
        margin: 1rem auto;
        @include fontrem(1.4);
      }
    }

    &:hover {
      .block-link-image img {
        opacity: 1.0;
        transition: opacity 0.2s ease-in-out;
      }
      .block-link-header a {
        background: darken($blue, 10%);
      }
    }

  } // .block-link-item
} // .block-links-container