// File path to the WordPress Media Library folder
$libraryPath: '../../uploads/';
// File path to the theme's image folder
$imgPath: 'img/';

//
// Brand Colors
// ------------------------------
$blue: #00529b;
$darkGray: #54575A;
$lightGray: #dcdbdb;
$red: #e34532;

//
// Other Colors
// ------------------------------
$white: #fff;
$black: #222;
$darkBlue: darken($blue, 9%);

//
// Fonts
// ------------------------------
$sans: -apple-system, BlinkMacSystemFont, sans-serif;
$serif: Georgia, serif;

$headerFont: 'BebasNeue', $sans;
$subHeaderFont: 'Source Sans Pro', $sans;
$bodyFont: 'proxima-nova', $sans;

//
// Media queries
// ------------------------------
$phablet:     "min-width: 544px";
$tablet:      "min-width: 768px";
$tablet-wide: "min-width: 992px";
$laptop:      "min-width: 1200px";
$desktop:     "min-width: 1600px";

$under-phablet:     "max-width: 543px";
$under-tablet:      "max-width: 767px";
$under-tablet-wide: "max-width: 991px";
$under-laptop:      "max-width: 1199px";
$under-desktop:     "max-width: 1599px";

$portrait:  "orientation: portrait";
$landscape: "orientation: landscape";

$page-container-max-width: 1920px;
$page-container-max: "min-width: #{$page-container-max-width}";
