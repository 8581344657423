.gform_wrapper .gfield_required,
.validation_error,
.validation_message,
.gform_wrapper li.gfield_error ul.gfield_checkbox,
.gform_wrapper li.gfield_error ul.gfield_radio {
  color: $red !important;
}

.gform_wrapper li input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
.gform_wrapper li textarea {
  border: 2px solid $lightGray !important;
  transition: border 0.2s ease-in-out !important;

  &:focus {
    border: 2px solid $blue !important;
  }
}

.gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
.gform_wrapper li.gfield_error textarea {
  border: 1px solid $red !important;
}

.gform_wrapper div.validation_error {
  color: $red !important;
  border-top: none !important;
  border-bottom: none !important;
}

.gform_wrapper .gfield_error .gfield_label,
.gform_wrapper li.gfield_error div.ginput_complex.ginput_container label {
  color: $black !important;
}

.gform_wrapper li.gfield.gfield_error,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
  border-top: none !important;
  border-bottom: none !important;
}

.gform_wrapper .field_description_below .gfield_description {
  padding-top: 0.25rem !important;
}

.gform_wrapper input {
  outline: none !important;
}
