.page-template-page-bio {

  .fullwidth-hero-bg {
    min-height: 230px;

    @media ($tablet) {
      min-height: inherit;
    }
  }

  .fullwidth-hero-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.blog-header.bio-header {
  padding-top: 0.5em;
}

.section-body,
.page-content {

  &.bio-content {

    @media ($tablet) {
      padding: 0;
    }

    p {
      margin-top: 0;
    }
  }

}

.bio-headshot {
  padding-bottom: 1em;

  @media ($phablet) {
    float: left;
    width: 50%;
    padding: 0 1em 0.5em 0;
  }

  @media ($tablet) {
    width: 50%;
    padding: 0 2em 1em 0;
  }

  @media ($tablet-wide) {
    width: 30%;
  }

  img {
    max-width: 100%;
  }
}


.bio-sidebar {
  padding: 0 2rem 2rem;

  @media ($tablet) {
    padding: 0.5em 2rem 2rem 2rem;
  }
}

// Hide bio sidebar content in sidebar or content area, depending
.section-body .bio-sidebar-content {
  display: block;

  @media ($tablet-wide) {
    display: none;
  }
}

.bio-sidebar .bio-sidebar-content {
  display: none;

  @media ($tablet-wide) {
    display: block;
  }
}
