// Action Bar
.action-bar {
  background: rgba($blue, 0.8);
  border-bottom: 1px solid darken($darkBlue, 5%);
  min-height: 55px;

  .col-25 {
    height: 100%;
    min-height: 55px;

    @media ($tablet) {
      width: 50%;
    }

    @media ($tablet-wide) {
      width: 25%;
    }

    &:first-child,
    &:last-child {
      display: none; // only show middle two sections on mobile

      &.visible {
        display: block;
      }

      &.visible-tablet {
        display: block;

        @media ($tablet-wide) {
          display: none;
        }
      }

      @media ($tablet-wide) {
        display: block;
      }
    }

    &:nth-child(1) {
      background: darken($blue, 4%);
    }

    &:nth-child(2) {
      background: darken($blue, 8%);
    }

    &:nth-child(3) {
      background: darken($blue, 12%);
    }

    &:nth-child(4) {
      background: darken($blue, 16%);
    }

  } // .col-25 - override default widths & display

  .action-col {
    height: 100%;
    min-height: 55px;
  }

  a {
    display: block;
    color: $white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 1.8rem;
    transition: all 0.2s ease-in-out;

    @media($phablet) {
      padding: 1.5rem;
    }

    &:hover {
      color: $lightGray;
      background: darken($darkBlue, 10%);
    }
  } // a

  img {
    max-height: 100%;
  }

  .job-search-container {
    text-align: center;

    //@media ($tablet) and ($under-tablet-wide) {
    //  display: table;
    //}

    .job-search {
      padding-top: 1rem;

      //@media ($tablet) and ($under-tablet-wide) {
      //  display: table-cell;
      //  vertical-align: middle;
      //}

      .search-field {
        @media ($tablet) {
          width: 50%;
        }
      }
    }
  } // .job-search-container

} // .action-bar
