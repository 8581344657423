//
// Bebas Neue
//
@font-face {
  font-family: 'BebasNeue';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/bebasneue.eot');
  src: url('fonts/bebasneue.eot?#iefix') format('embedded-opentype'),
       url('fonts/bebasneue.woff2') format('woff2'),
       url('fonts/bebasneue.woff') format('woff'),
       url('fonts/bebasneue.ttf') format('truetype'),
       url('fonts/bebasneue.svg#bebas_neueregular') format('svg');
}
