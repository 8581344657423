.ad-col {
  @media ($tablet) {
    padding-right: 2rem; // keep ad away from the column border
  }
}

.advertisement-space {
  padding: 0 10px;

  img {
    display: block;
    max-width: 100%;
    margin: 20px auto;
  }
}

.sidebar-ad {
  width: 100%;
  max-width: 200px;
  height: 200px;
  margin: 4em auto;

  img {
    width: 100%;
    height: auto;
    max-width: 200px;
    max-height: 200px;
  }
}

.ad-placeholder-content {
  text-align: center;
  padding-top: 80px;
  font-size: 30px;
  font-family: $headerFont;
  color: $white;
}

.fullwidth-ad {
  width: 100%;
  padding: 0.5em;
  text-align: center;

  @media ($tablet) {
    padding: 1em;
  }

  img {
    max-width: 100%;
    height: auto;
  }
} // .fullwidth-ad
